/*!
 * Stylesheet for the Date Range Picker, for use with Bootstrap 3.x
 *
 * Copyright 2013 Dan Grossman ( http://www.dangrossman.info )
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Built for http://www.improvely.com
 */

 .daterangepicker.dropdown-menu {
  max-width: none;
  z-index: 3000;
}

.daterangepicker.opensleft .ranges, .daterangepicker.opensleft .calendar {
  float: left;
  margin: 5px;
}

.daterangepicker.opensright .ranges, .daterangepicker.opensright .calendar {
  float: right;
  margin: 0;
  padding: 5px;
}

.daterangepicker .ranges {
  width: 170px;
  text-align: left;
}

.daterangepicker .ranges .range_inputs>div {
  float: left;
}

.daterangepicker .ranges .range_inputs>div:nth-child(2) {
  padding-left: 3px;
}

.daterangepicker .calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker.show-calendar .calendar {
    display: block;
}

.daterangepicker .calendar.single .calendar-date {
  border: none;
}

.daterangepicker .calendar th, .daterangepicker .calendar td {
  font-family: "Source Sans Pro", "Segoe UI", "Droid Sans", Tahoma, Arial, sans-serif;
  white-space: nowrap;
  text-align: center;
  min-width: 32px;
}

.daterangepicker .ranges label {
  color: #333;
  display: block;
  font-size: 11px;
  font-weight: normal;
  height: 20px;
  line-height: 20px;
  margin-bottom: 2px;
  text-shadow: #fff 1px 1px 0px;
  text-transform: uppercase;
  width: 74px;
}

.daterangepicker .ranges input {
  font-size: 11px;
}

.daterangepicker .ranges .input-mini {
  background-color: #f7f8fa;
  border: 1px solid #cdced1;
  color: rgba(26, 26, 26, 0.5);
  display: block;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  margin: 0 0 10px 0;
  padding: 0 6px;
  width: 78px;
  border-radius: 1px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.daterangepicker .ranges li {
  font-size: 14px;
  background: #edeef0;
  border: 1px solid #edeef0;
  color: #1a1a1a;
  padding: 3px 8px;
  margin-bottom: 8px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
  cursor: pointer;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
  background: #08c;
  border: 1px solid #08c;
  color: #fff;
}

.daterangepicker .calendar-date {
  border: 1px solid #ddd;
  padding: 4px;
  background: #fff;
  background: rgba(255,255,255,0.92);
  border-radius: 1px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 8px auto 0 auto;
  line-height: 30px;
}

.daterangepicker {
  position: absolute;
  background: #fff;
  background: rgba(255,255,255,0.98);
  top: 100px;
  left: 20px;
  padding: 5px;
}

.daterangepicker.opensleft:before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.daterangepicker.opensleft:after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: '';
}

.daterangepicker.opensright:before {
  position: absolute;
  top: -7px;
  left: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.daterangepicker.opensright:after {
  position: absolute;
  top: -6px;
  left: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: '';
}

.daterangepicker table {
  width: 100%;
  margin: 0;
}

.daterangepicker td, .daterangepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  white-space: nowrap;
}

.daterangepicker td.off {
  color: #999;
}

.daterangepicker td.disabled {
  color: #999;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background: #eee;
}

.daterangepicker td.in-range {
  background: #ebf4f8;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: #3071a9;
  color: #fff;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin-bottom: 0;
}

.daterangepicker_start_input {
  float: left;
}

.daterangepicker_end_input {
  float: left; 
  padding-left: 11px
}

.daterangepicker th.month {
  width: auto;
}
